import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { initialRepeatMedicationState, RepeatMedicationState } from "../repeat-medication-state";
import { repeatMedicationActions } from "../actions/repeat-medication.actions";

const reducer: ActionReducer<RepeatMedicationState, Action> = createReducer(
  initialRepeatMedicationState,

  on(repeatMedicationActions.setClaimDetails,
    (state, props): RepeatMedicationState => ({
      ...state,
      condition: props.condition,
      amount: props.amount,
      medications: props.medications,
      treatmentFrom: props.treatmentFrom,
      treatmentTo: props.treatmentTo,
    }),
  ),

  on(repeatMedicationActions.updatePrescriptionFiles,
    (state, { files }): RepeatMedicationState => ({
      ...state,
      prescriptionFiles: [...files],
    })),

  on(repeatMedicationActions.setHasPrescription,
    (state, { hasPrescription }): RepeatMedicationState => ({
      ...state,
      hasPrescription,
    })),
);

export const repeatMedicationReducer = (state: RepeatMedicationState, action: Action) =>
  reducer(state, action);