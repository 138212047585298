import { Injectable } from "@angular/core";
import { ClaimService } from "@features/claims/services/claim.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { claimDetailActions } from "../actions/claim-detail.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { PolicySelectors } from "@features/policies/store/policy.selectors";
import { Store } from "@ngrx/store";
import { concatLatestFrom } from "@ngrx/operators";
import { PolicyService } from "@features/policies/services/policy.service";

@Injectable()
export class ClaimDetailEffects {
  constructor(
    private actions$: Actions, 
    private claimService: ClaimService,
    private store: Store,
    private policyService: PolicyService,
  ) {}

  createClaim$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(claimDetailActions.createClaim),
      switchMap(({ formData, isV2Claim }) =>
        this.claimService.createClaim(formData, isV2Claim).pipe(
          map((response) => claimDetailActions.createClaimSuccess({ response })),
          catchError((error: unknown) => 
            of(claimDetailActions.createClaimFailure({ error: error }))),
        ),
      ),
    );
  });

  loadClaimConditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(claimDetailActions.repeatMedPageLoaded),
      concatLatestFrom(() => this.store.select(PolicySelectors.selectPolicy)),
      switchMap(([props, policy]) => {
        const claimConditions$ = this.claimService.getClaimConditions(props.riskType);
        const policyConditions$ = 
        this.policyService.getPolicyDistinctConditions(policy.policyUniqueRef);
        return forkJoin([claimConditions$, policyConditions$]).pipe(
          map(([claimConditions, policyConditions]) => {
            // for existing policy conditions, we only have the condition name 
            /** get the id for the existing policy conditions from the list of 
             * all possible claim conditions */
            const policyConditionsMapped = policyConditions.flatMap(pc => {
              const id = claimConditions.find(
                (cc) => pc.condition === cc.condition,
              )?.id;
    
              return id ? { id, ...pc } : []; 
            });
            const conditions=  claimConditions.map(c => {
              const existingPolicyCondition = policyConditionsMapped.find((pc) => pc.id === c.id);

              // only show claim conditions in the list that are active
              // or existing conditions on the policy.
              // conditions will only have claim id's if they existing policy conditions
              if (c.status || existingPolicyCondition) {
                return {
                  id: c.id,
                  condition: c.condition,
                  claimID: existingPolicyCondition ? existingPolicyCondition.claimID : null,
                  claimSubID: existingPolicyCondition ? existingPolicyCondition.claimSubID : null,
                };
              }
            }).filter(c => !!c);
            return claimDetailActions.loadPolicyConditionsSuccess({ conditions });
          }),
        );
      },
        
      ),
    );
  });
}