import { PolicyPaymentFrequency } from '@features/policies/enums/policy-payment-frequency.enum';
import { PolicyPaymentStatus } from '@features/policies/enums/policy-payment-status.enum';
import { MockPolicyPaymentsMap } from './policy-payments';
import { Policy } from '@interfaces/policy';
import { PolicySummary } from '@interfaces/policy-summary.interface';

const InactiveCatPolicy = {
  policyUniqueRef: '9999-9',
  previousPolicyRef: undefined,
  coverLevel: 'Superior Plus',
  coverCategory: 'Lifetime',
  status: 'Cancelled',
  statusReason: 'Premium not paid',
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Peanut',
    petBreed: 'Burmese',
    petDOB: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 5),
    petColour: 'Apricot',
    petGender: 'Male',
    microchipNo: '12335645',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'cat',
    horseActivity: null,
    horsePostcode: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * -30),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * -30),
  coPayment: 20,
  excess: 99,
  monthlyTotal: 11.55,
  annualTotal: 138.6,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.Cancelled,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 24,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: false,
  prizeDrawWinner: null,
};

const InactiveDogPolicy = {
  policyUniqueRef: '10000-1',
  previousPolicyRef: undefined,
  coverLevel: 'Prime Plus',
  coverCategory: 'Time Limited',
  status: 'Not taken up',
  statusReason: 'No longer needs',
  inceptionDate: new Date(),
  ipidUri: 'http://www.animalfriends.co.uk',
  policyUri: 'http://www.animalfriends.co.uk',
  termsUri: undefined,
  vetFeeCapsUri: 'http://www.animalfriends.co.uk',
  pet: {
    petName: 'Presley',
    petBreed: 'German Shepherd',
    petDOB: new Date(2016, 1, 1),
    petColour: 'Golden',
    petGender: 'Male',
    microchipNo: '12335645',
    heightHands: null,
    heightInches: null,
    riderDOB: null,
    riderFirstName: null,
    riderSurname: null,
    type: 'dog',
    horseActivity: null,
    horsePostcode: null,
    passportNo: null,
    horseOwnershipStatus: null,
    horsePurchaseDate: null,
  },
  expiryDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * -60),
  renewalDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * -60),
  coPayment: 0,
  excess: 99,
  monthlyTotal: 20.83,
  annualTotal: 250,
  outstandingPayment: 0,
  paymentStatus: PolicyPaymentStatus.Cancelled,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  preferredPaymentDay: 24,
  customerActionRequired: true,
  voluntaryExcess: null,
  productId: null,
  productVersion: 'V1 09162021',
  payments: MockPolicyPaymentsMap.NoPayments,
  isLive: false,
  prizeDrawWinner: null,
};

const InactiveHorsePolicy = {
  policyUniqueRef: 'HH2600710',
  previousPolicyRef: '',
  inceptionDate: new Date('2017-04-06T00:00:00'),
  coverLevel: 'Standard',
  coverCategory: '',
  excess: null,
  coPayment: null,
  monthlyTotal: 38.36,
  annualTotal: 460.32,
  outstandingPayment: 38.36,
  customerActionRequired: true,
  paymentStatus: PolicyPaymentStatus.Cancelled,
  preferredPaymentDay: 6,
  paymentFrequency: PolicyPaymentFrequency.Monthly,
  expiryDate: new Date('2018-04-05T23:59:00'),
  renewalDate: new Date('2018-04-06T00:00:00'),
  status: 'Cancelled',
  statusReason: 'Missing Payment',
  ipidUri: null,
  policyUri:
    'https://animalfriendscusthub.blob.core.windows.net/policydocuments/horse-standard-policy-wording.pdf',
  termsUri: null,
  policyHolder: {
    clientNumber: 1669397,
    title: 'Mrs',
    forename: 'Lorraine',
    surname: 'Smith1669397',
    building: '1 High Street',
    street: '',
    town: 'Nailsea',
    postcode: 'RG21 6SZ',
    emailAddress: 'hubtest01@mailinator.com',
    phoneNumber: '0122222222',
    eveningNumber: '',
    mobilePhone: '',
    dob: '1973-08-28T00:00:00',
    marketingEmail: true,
    marketingSMS: false,
    marketingPhone: true,
    marketingPost: true,
    documentsEmail: true,
  },
  pet: {
    petName: 'Abby Diamond',
    petDOB: new Date('2006-01-01T00:00:00'),
    petBreed: 'Connemara',
    petColour: 'Grey',
    petGender: 'Mare',
    type: 'horse',
    heightHands: 14,
    heightInches: 2,
    horsePostcode: 'BS48 2QH',
    horseActivity: 'B',
    riderFirstName: 'Lorraine',
    riderSurname: 'Price',
    riderDOB: new Date('1973-08-28T00:00:00'),
    horseOwnershipStatus: 'Purchased',
    horsePurchaseDate: new Date(2000, 1, 10),
  },
  productId: null,
  productVersion: 'V1 09162021',
  isLive: false,
};

export const MockInactivePolicies: Policy[] = [
  InactiveCatPolicy,
  InactiveDogPolicy,
];

export const MockInactivePolicyMap = {
  InactiveCatPolicy,
  InactiveDogPolicy,
  InactiveHorsePolicy,
};

export const MockInactivePolicySummaries: PolicySummary[] =
  MockInactivePolicies.map((p) => ({ ...p, renewal: null }));
